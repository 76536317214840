@import 'variables';

// Clearfix mixin
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// Ellipsis mixin for text truncation
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Normalize element mixin
@mixin normalize-element {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  border: 0;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  white-space: nowrap;
  appearance: none;
  text-decoration: none;
  text-transform: none;
  line-height: normal;
  box-shadow: none;
}

// Link style mixin
@mixin link {
  color: colors('blue');
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: colors('offBlack');
  }
}

// Drop shadow mixin
@mixin dropShadow($x: 0, $y: 0.5rem, $blur: 2rem, $opacity: 0.15) {
  box-shadow: $x $y $blur rgba(0, 0, 0, $opacity);
}

// Placeholder text mixin
@mixin placeholder {
  &::placeholder {
    @content;
  }
}

// Theme map
$themes: (
  'dark': 'dark',
  'blue': 'blue',
  'red': 'red',
  'green': 'green'
);

// Theme mixin
@mixin theme($color: map-get($themes, 'blue')) {
  $selector: ':root body.app--' + $color + 'Theme &';
  @at-root #{$selector},
  :host-context(.app--#{$color}Theme) & {
    @content;
  }
}
