.fileUpload {
  margin-top: -50px;
  height: auto;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  &__input {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    text-indent: -1000px;
  }
  &__button {
    cursor: pointer;
  }
}
