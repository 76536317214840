@use 'sass:math';

@import '../../../../../../../libs/material/style/variables';
@import '../../../../../../../libs/material/style/mixins.scss';

$fileBrowser: (
  "width": 30em,
  "itemHeight": 2.4rem,
  "itemPadding": 1.5rem,
  "levelIndent": 2rem,
  "hoverColor": #e7e7e7,
  "darkHoverColor": #3e3e3e,
  "caretWidth": 1.5rem,
  "states": (
    "NEW": "1",
    "MODIFIED": "2",
    "DELETED": "3",
  ),
);

@mixin fileBrowserItem($type) {
  .fileBrowser__#{$type} {
    @content;
  }
}

@include fileBrowserItem("file") {
  height: map-get($fileBrowser, "itemHeight");
  padding: 0 map-get($fileBrowser, "itemPadding");
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fileBrowser__fileButtons.dots {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    .dots-icon {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }
  }

  &NameSpan {
    max-width: 100%;
    padding: 0.3rem;
    @include ellipsis();
  }

  &Name {
    max-width: 100%;
    padding: 0.3rem;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    @include ellipsis();
  }

  &NameContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    max-width: calc(100% - 5rem);

    &--invalid {
      z-index: 2;
    }
  }

  &NameError {
    position: absolute;
    width: calc(100% + 6px);
    top: 23px;
    left: 3px;
    padding: 3px;
    line-height: 1.2;
    border-radius: 0 0 2px 2px;
    background: colors('red');
    font-size: 1.2rem;
    color: #fff;
    z-index: 10;
  }

  &New {
    $size: 0.6em;
    position: absolute;
    pointer-events: none;
    border-radius: 200px;
    background: colors('blue');
    height: $size;
    width: $size;
    // Convert rem to em using math.div
    $itemHeightEm: math.div(map-get($fileBrowser, "itemHeight"), 1rem) * 1em;

    // Calculate top and right with math.div
    top: (math.div($itemHeightEm, 2)) - (math.div($size, 2));
    right: (math.div(map-get($fileBrowser, "itemPadding"), 1rem) * 1em) + $size / 2;

    transition: opacity 0.2s ease;
  }

  &.fileBrowser__file--selected {
    background: #3466b9;

    .fileBrowser__fileNew {
      background: white;
    }

    .fileBrowser__localizedFileIcon {
      background: #3466b9;
    }

    &:hover {
      background: darken(#3466b9, 3%) !important;
    }
  }

  .fileBrowser__localizedFileIcon {
    width: 9px;
    height: 9px;
    line-height: 8px;
    border-radius: 100px;
    color: #ffffff;
    font-size: 8px;
    position: absolute;
    left: 13px;
    top: 3px;
    background: #292929;
  }

  &:hover {
    background-color: map-get($fileBrowser, "hoverColor");

    .fileBrowser__fileButtons .dots-icon {
      visibility: visible;
      opacity: 1;
    }

    .app--darkTheme & {
      background-color: map-get($fileBrowser, "darkHoverColor") !important;

      .fileBrowser__fileButtons .dots-icon {
        visibility: visible;
        opacity: 1;
      }

      .fileBrowser__fileNew {
        opacity: 0;
      }
    }
  }
}
