
@-webkit-keyframes #{ANIMATION_BASE_NAME__slideTop--in } {
  0%  {
    transform: translateY(-1000px);
    opacity: 0;
  }100%  {
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes #{ANIMATION_BASE_NAME__slideTop--in } {
  0%  {
    transform: translateY(-1000px);
    opacity: 0;
  }100%  {
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes #{ANIMATION_BASE_NAME__slideTop--in } {
  0%  {
    transform: translateY(-1000px);
    opacity: 0;
  }100%  {
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes #{ANIMATION_BASE_NAME__slideTop--in } {
  0%  {
    transform: translateY(-1000px);
    opacity: 0;
  }100%  {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes #{ANIMATION_BASE_NAME__slideTop--in } {
  0%  {
    transform: translateY(-1000px);
    opacity: 0;
  }100%  {
    transform: translateY(0);
    opacity: 1;
  }
}



@-webkit-keyframes #{ANIMATION_BASE_NAME__slideTop--out } {
  0%  {
    transform: translateY(0);
    opacity: 1;
  }100%  {
    transform: translateY(-1000px);
    opacity: 0;
  }
}



@-moz-keyframes #{ANIMATION_BASE_NAME__slideTop--out } {
  0%  {
    transform: translateY(0);
    opacity: 1;
  }100%  {
    transform: translateY(-1000px);
    opacity: 0;
  }
}



@-ms-keyframes #{ANIMATION_BASE_NAME__slideTop--out } {
  0%  {
    transform: translateY(0);
    opacity: 1;
  }100%  {
    transform: translateY(-1000px);
    opacity: 0;
  }
}



@-o-keyframes #{ANIMATION_BASE_NAME__slideTop--out } {
  0%  {
    transform: translateY(0);
    opacity: 1;
  }100%  {
    transform: translateY(-1000px);
    opacity: 0;
  }
}



@keyframes #{ANIMATION_BASE_NAME__slideTop--out } {
  0%  {
    transform: translateY(0);
    opacity: 1;
  }100%  {
    transform: translateY(-1000px);
    opacity: 0;
  }
}
