@import 'variables';

body {
	&.app--darkTheme {
		color: colors('offWhite');
		background: colors('offBlack');
	}

	&.app--blueTheme {
		color: colors('offWhite');
		background: colors('blue');
	}

	&.app--greenTheme {
		color: colors('offWhite');
		background: #1dab12;
	}
}
