
$root: '/' !default;
$bfMaterialDirectory: '/' !default;

@function colors($color) {
  @return map-get($colors, $color);
}

@function fontSize($size) {
  @return map-get($fontSize, $size);
}

@function height($size) {
  @return map-get($heights, $size);
}

@function weight($weight) {
  @return map-get($weights, $weight);
}



$app: (
  'maxWidth': 120rem,
  'sectionPadding': 2.5rem,
  'topMenuHeight': 5rem,
  'topMenuPadding': 0.5rem,
  'footerHeight': 10rem
);



$breakpoints: (
  'tiny': 480px,
  'small': 768px,
  'medium': 992px,
  'large': 1200px
);



$weights: (
  'thin': 100,
  'extraLight': 200,
  'light': 300,
  'normal': 400,
  'default': 400,
  'medium': 500,
  'semiBold': 600,
  'bold': 700,
  'extraBold': 800,
  'ultraBold': 900
);

$fontWeight: $weights;

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/





$fontSize: (
  'tiny': 0.8rem,
  'small': 1rem,
  'button': 1.1rem,
  'default': 1.2rem,
  'medium': 1.4rem,
  'large': 1.8rem,
  'extraLarge': 2.2rem
);



$colors: (
  'white': #fff,
  'offWhite': #f7f7f7,
  'black': #000,
  'offBlack': #363636,
  'darkGrey': #9B9B9B,
  'grey': #D8D8D8,
  'lightGrey': #f8f8f8,
  'green': #2CBC45,
  'yellow': #FFED00,
  'orange': #FF4838,
  'gold': #de9516,
  'lightGold': #fffdf4,
  'lightRed': #fbe6e6,
  'red': #FF594B,
  'lightBlue': #edf5ff,
  'blue': #1B75DD,
  'darkBlue': #1558a8
);



$heights: (
  'tiny': 2rem,
  'small': 3rem,
  'default': 4rem,
  'large': 5rem
);
