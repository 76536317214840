@-webkit-keyframes #{ANIMATION_BASE_NAME__zoomBack--in } {
  0%  {
    opacity: 0;
    transform: scale(0.5);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }80%  {
    opacity: 1;
    transform: scale(0.95);
  }100%  {
    opacity: 1;
    transform: none;
  }
}@-moz-keyframes #{ANIMATION_BASE_NAME__zoomBack--in } {
  0%  {
    opacity: 0;
    transform: scale(0.5);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }80%  {
    opacity: 1;
    transform: scale(0.95);
  }100%  {
    opacity: 1;
    transform: none;
  }
}@-ms-keyframes #{ANIMATION_BASE_NAME__zoomBack--in } {
  0%  {
    opacity: 0;
    transform: scale(0.5);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }80%  {
    opacity: 1;
    transform: scale(0.95);
  }100%  {
    opacity: 1;
    transform: none;
  }
}@-o-keyframes #{ANIMATION_BASE_NAME__zoomBack--in } {
  0%  {
    opacity: 0;
    transform: scale(0.5);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }80%  {
    opacity: 1;
    transform: scale(0.95);
  }100%  {
    opacity: 1;
    transform: none;
  }
}@keyframes #{ANIMATION_BASE_NAME__zoomBack--in } {
  0%  {
    opacity: 0;
    transform: scale(0.5);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }80%  {
    opacity: 1;
    transform: scale(0.95);
  }100%  {
    opacity: 1;
    transform: none;
  }
}



@-webkit-keyframes #{ANIMATION_BASE_NAME__zoomBack--out } {
  0%  {
    opacity: 1;
    transform: none;
  }20%  {
    opacity: 1;
    transform: scale(0.95);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }100%  {
    opacity: 0;
    transform: scale(0.5);
  }
}



@-moz-keyframes #{ANIMATION_BASE_NAME__zoomBack--out } {
  0%  {
    opacity: 1;
    transform: none;
  }20%  {
    opacity: 1;
    transform: scale(0.95);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }100%  {
    opacity: 0;
    transform: scale(0.5);
  }
}



@-ms-keyframes #{ANIMATION_BASE_NAME__zoomBack--out } {
  0%  {
    opacity: 1;
    transform: none;
  }20%  {
    opacity: 1;
    transform: scale(0.95);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }100%  {
    opacity: 0;
    transform: scale(0.5);
  }
}



@-o-keyframes #{ANIMATION_BASE_NAME__zoomBack--out } {
  0%  {
    opacity: 1;
    transform: none;
  }20%  {
    opacity: 1;
    transform: scale(0.95);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }100%  {
    opacity: 0;
    transform: scale(0.5);
  }
}



@keyframes #{ANIMATION_BASE_NAME__zoomBack--out } {
  0%  {
    opacity: 1;
    transform: none;
  }20%  {
    opacity: 1;
    transform: scale(0.95);
  }50%  {
    opacity: 1;
    transform: scale(1.1);
  }100%  {
    opacity: 0;
    transform: scale(0.5);
  }
}
