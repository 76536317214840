@import "../../../../styles/variables";
.uploadDialog {
  &__addFiles {
    height: auto;
    text-align: center;
    position: relative;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border:
      1px dashed
      colors('green');
    color: colors('green');
    &:hover {
      background: #f6fff6;
    }
    &Input {
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0;
      cursor: pointer;
      text-indent: -1000px;
    }
  }
  &__files {
    .uploadDialog__file {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;

      i {
        cursor: pointer;
      }
    }
  }
}

.uiDialog__buttons {
  ui-button {
    margin-top: 20px;
    margin-right: 10px;
  }
}
