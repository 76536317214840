@import "../../style/variables";
@-webkit-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-ms-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  40% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  100% {
    border-color: colors('blue');
    background: #ffffff;
  }
}

@-moz-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  40% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  100% {
    border-color: colors('blue');
    background: #ffffff;
  }
}

@-ms-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  40% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  100% {
    border-color: colors('blue');
    background: #ffffff;
  }
}

@-o-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  40% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  100% {
    border-color: colors('blue');
    background: #ffffff;
  }
}

@keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  40% {
    border-color:colors('red');
    background: lighten(colors('red'), 32);
  }
  100% {
    border-color: colors('blue');
    background: #ffffff;
  }
}

bfTagInput {
  .bfTagInput {
    background:colors('white');
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    color: #777;
    outline: none;
    transition:
      color 0.15s ease,
      border-color 0.15s ease;
    max-width: 100%;
    min-height: height('default');
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.3rem 0.4rem 0.3rem;
    &--disabled {
      opacity: 0.5;
    }
    &--flashError {
      animation: bfTagInput__tagErrorFlash 0.65s ease;
    }
    &--focus {
      border-color: colors('blue');
      .bfTagInput__tag {
        background: colors('blue');
        color: white;
        &Remove {
          color: white;
        }
        &--erasing {
          transform: scale(0.95);
          box-shadow:
            0 0 0 2px white,
            0 0 0 3px
            colors('blue');
        }
      }
    }
    &__tag {
      background-color: #eee;
      display: inline-block;
      line-height: height('default') - 1.2rem;
      height: height('default') - 1.2rem;
      margin: 0.5rem 0.2rem 0rem 0.2rem;
      padding: 0 0.5rem;
      flex-grow: 0;
      transition:
        background 0.1s ease,
        transform 0.25s ease;
      animation: bfTagInput__tag 0.15s ease;
      &Text {
        user-select: none;
      }
      &Remove {
        color: gray;
        font-size: 1.5rem;
        cursor: pointer;
        vertical-align: -0.1rem;
      }
    }
    &__input {
      border: none !important;
      background: rgba(0, 0, 0, 0) !important;
      min-height: height('default') - 1.2rem;
      height: height('default') - 1.2rem;
      vertical-align: top;
      flex-grow: 1;
      min-width: 15rem;
      margin: 0.5rem 0.2rem 0 0.2rem;
    }
  }
}
