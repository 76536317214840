.bfInlineEdit__input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  word-break: break-all;
  letter-spacing: inherit;
  z-index: 1;
  outline: 1px solid rgba(0, 0, 0, 0);
  min-width: 1em;
  min-height: 1em;
  max-width: 100%;
}
.bfInlineEdit__input:hover:not(.bfInlineEdit__input--preventClick)::before {
  opacity: 0.7;
  z-index: -1;
}
.bfInlineEdit__input input {
  padding: 0;
  margin: 0;
  cursor: text;
  background-color: transparent;
  outline: none;
  border: none;
  white-space: nowrap;
  font-size: inherit;
  color: inherit;
  max-width: 100%;
  letter-spacing: 0.42;
  line-height: inherit;
  position: relative;
}
.bfInlineEdit__input input:hover {
  background: rgba(0, 0, 0, 0.1);
}
.bfInlineEdit__input::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  border-radius: 2px;
  background: white;
  z-index: 0;
  border: 1px solid #dfdfdf;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.app--darkTheme .bfInlineEdit__input::before {
  background: rgba(0, 0, 0, 0.3);
}
.bfInlineEdit__input--active input {
  background: transparent;
  z-index: 1;
}
.bfInlineEdit__input--active::before {
  opacity: 1 !important;
}
.bfInlineEdit__input--inValid {
  position: relative;
}
.bfInlineEdit__input--inValid input {
  outline-color: colors("red") !important;
}

/**
 * An arrow mixin for SASS, based on @shojberg's "cssarrowplease.com"
 * @param {Position} $position - top, right, bottom, or left
 * @param {List} $arrow - list containing size and color of the arrow
 * @param {List} $border - optional list containing size and color of the border (default: 0 white)
 */
.bfMenu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em 0;
  min-width: 8em;
  max-width: 25em;
  overflow: visible;
  z-index: 10000000000000000;
  opacity: 0;
  transition: opacity 0.1s ease;
  background: colors("white");
  box-shadow: 0 8px 18px 0 rgba(82, 97, 115, 0.12), 0 2px 8px 0 rgba(54, 54, 54, 0.25);
  border-radius: 2px;
}
.app--darkTheme .bfMenu {
  background: colors("black");
}
.bfMenu__list {
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 25em;
}
.bfMenu__list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.bfMenu__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: colors("lightGrey");
}
.bfMenu__list::-webkit-scrollbar-track {
  background: colors("white");
  border-radius: 0;
}
.bfMenu__item {
  line-height: 2.8em;
  display: block;
  cursor: pointer;
  padding: 0 1.2em;
  position: relative;
  user-select: none;
  color: colors("black");
}
.app--darkTheme .bfMenu__item {
  color: colors("white");
}
.bfMenu__item--disabled {
  pointer-events: none;
  opacity: 0.5;
}
.bfMenu__item--seperator {
  background: colors("offWhite");
  height: 1px;
  margin: 3px 0;
}
.app--darkTheme .bfMenu__item--seperator {
  background: colors("offBlack");
}
.bfMenu__item--seperator:hover {
  background: colors("offWhite");
}
.app--darkTheme .bfMenu__item--seperator:hover {
  background: colors("offBlack");
}
.bfMenu__item:hover {
  background: colors("offWhite");
}
.app--darkTheme .bfMenu__item:hover {
  background: colors("offBlack");
}
.bfMenu__item ui-flag {
  margin-right: 5px;
}
.bfMenu__item ui-flag .ui-flag.tiny {
  vertical-align: -3px;
}
.bfMenu__description {
  font-size: 0.9rem;
  color: colors("darkGrey");
}
.bfMenu__checkbox {
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}
.bfMenu__checkbox bfCheckbox {
  display: block;
  pointer-events: none;
}
.bfMenu__checkbox .bfCheckbox {
  position: absolute;
  pointer-events: none;
}
.bfMenu__icon {
  font-size: 15px;
  margin-right: 4px;
}
.bfMenu--showArrow.bfMenu--top:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bfMenu--showArrow.bfMenu--top:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: colors("white");
  border-width: 6px;
  margin-left: -6px;
}
.bfMenu--showArrow.bfMenu--bottom:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bfMenu--showArrow.bfMenu--bottom:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: colors("white");
  border-width: 6px;
  margin-top: -6px;
}
.bfMenu--showArrow.bfMenu--right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bfMenu--showArrow.bfMenu--right:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: colors("white");
  border-width: 6px;
  margin-top: -6px;
}
.bfMenu--showArrow.bfMenu--left:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bfMenu--showArrow.bfMenu--left:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: colors("white");
  border-width: 6px;
  margin-top: -6px;
}
.bfMenu--bfMultiSelect {
  color: colors("black");
}
.bfMenu--bfMultiSelect .bfMenu__item {
  line-height: 3.5rem;
}
.bfMenu--bfMultiSelect .bfMenu__item--selected {
  font-weight: weight("semiBold");
}
.bfMenu--bfMultiSelect .bfMenu__item .bf-icon {
  font-size: 12px;
}
.bfMenu--bfMultiSelect .bfMenu__label {
  padding-left: 2.5rem;
}