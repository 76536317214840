// Define the animation name as a variable
$animation-name-in: #{$ANIMATION_BASE_NAME}__slideUpBack--in;
$animation-name-out: #{$ANIMATION_BASE_NAME}__slideUpBack--out;

// Mixin for vendor prefixes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @-ms-keyframes #{$name} { @content; }
  @-o-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}

// Slide Up Back In Animation
@include keyframes($animation-name-in) {
  from, 80%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  }

  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  35% {
    transform: translate3d(0, -10px, 0);
  }

  80% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Slide Up Back Out Animation
@include keyframes($animation-name-out) {
  0% {
    opacity: 1;
    transform: none;
  }

  20% {
    opacity: 1;
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
