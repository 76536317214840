.blockTable {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 10px;

	&--faded {
		tbody {
			tr {
				box-shadow: none !important;

				td {
					border: none !important;
					background: #f3f3f3 !important;
					color: #999;
				}
			}
		}
	}

	thead {
		tr {
			th {
                font-size: 1.2rem;
				text-align: left;
				padding: 0 2rem;
				font-weight: normal;
				color: var(--ui-color-text-second);
			}
		}
	}

	tbody {
		tr {
			background: #ffffff;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			min-height: 45px;
			margin-bottom: 10px;

			&.blockTable--invalid {
				td {
					background: var(--ui-color-surface-alert);
					border-color: var(--ui-color-alert);;
				}
			}
		}

        td {
            position: relative;
            padding: 0 2rem;
            border: 1px solid var(--ui-color-border);
            border-left-width: 0;
            border-right-width: 0;
            height: 50px;
            white-space: nowrap;
            background: white;

            &:first-child {
                border-radius: 2px 0 0 2px;
                border-left-width: 1px;
            }

            &:last-child {
                border-radius: 0 2px 2px 0;
                border-right-width: 1px;
            }

            // & > ui-flag.ui-flag.tiny {
            //     vertical-align: -3px;
            //     margin-right: 5px;
            // }

            // &.blockTable__subText {
            //     color: #999;
            // }

            // &.blockTable__action {
            //     width: 45px;
            //     text-align: center;
            //     text-decoration: underline;
            //     cursor: pointer;
            // }
        }
	}
}
