@import '../../../../../../styles/themes.scss';
@import '../../../../../../libs/material/style/variables';

$tabHeight: 50px;

.fileTabs {
  height: $tabHeight;
  background: colors('offWhite');
  display: flex;

  &__tab {
    max-width: 200px;
    flex-grow: 1;
    height: $tabHeight;
    text-align: center;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:hover {
      .fileTabs__tabClose {
        opacity: 1;
      }

      .fileTabs__tabDirty {
        opacity: 0;
      }
    }

    &Inner {
      user-select: none;
      line-height: 40px;
      background: #ffffff;
      color: #333;
      border-radius: 4px 4px 0 0;
      border: 1px solid #eee;
      border-bottom: none;
      margin-top: 7px;
      margin-left: 10px;
      padding: 0 25px 2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &Close {
      opacity: 0;
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 10px !important;
      transition: opacity 0.1s ease;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: white;
      }
    }

    &Dirty {
      position: absolute;
      pointer-events: none;
      border-radius: 200px;
      background: colors('blue');
      height: 0.6em;
      width: 0.6em;
      top: 55%;
      right: 1.2rem;
      margin-top: -0.1rem;
      transition: opacity 0.1s ease;
    }
  }
}

:host-context(.app--darkTheme) {
	.fileTabs {
		background: theme-color('dark', 'secondaryColor');

		&__tab {
			&Inner {
				background: theme-color('dark', 'secondaryColor');
				color: #999;
				border-color: transparent;
				box-shadow: none;
			}

			&--active {
				.fileTabs__tabInner {
					background: theme-color('dark', 'mainColor');
					color: colors('offWhite');
					border-color: #424242;
					box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}
