@-webkit-keyframes #{ANIMATION_BASE_NAME__fade--in } {
  0%  {
    opacity: 0;
  }100%  {
    opacity: 1;
  }
}@-moz-keyframes #{ANIMATION_BASE_NAME__fade--in } {
  0%  {
    opacity: 0;
  }100%  {
    opacity: 1;
  }
}@-ms-keyframes #{ANIMATION_BASE_NAME__fade--in } {
  0%  {
    opacity: 0;
  }100%  {
    opacity: 1;
  }
}@-o-keyframes #{ANIMATION_BASE_NAME__fade--in } {
  0%  {
    opacity: 0;
  }100%  {
    opacity: 1;
  }
}@keyframes #{ANIMATION_BASE_NAME__fade--in } {
  0%  {
    opacity: 0;
  }100%  {
    opacity: 1;
  }
}


@-webkit-keyframes #{ANIMATION_BASE_NAME__fade--out } {
  0%  {
    opacity: 1;
  }100%  {
    opacity: 0;
  }
}


@-moz-keyframes #{ANIMATION_BASE_NAME__fade--out } {
  0%  {
    opacity: 1;
  }100%  {
    opacity: 0;
  }
}


@-ms-keyframes #{ANIMATION_BASE_NAME__fade--out } {
  0%  {
    opacity: 1;
  }100%  {
    opacity: 0;
  }
}


@-o-keyframes #{ANIMATION_BASE_NAME__fade--out } {
  0%  {
    opacity: 1;
  }100%  {
    opacity: 0;
  }
}


@keyframes #{ANIMATION_BASE_NAME__fade--out } {
  0%  {
    opacity: 1;
  }100%  {
    opacity: 0;
  }
}
