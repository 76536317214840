@import '../../../styles/themes.scss';

$appBottomHeight: 40px;
$appTopHeight: 40px;

.app {
	width: 100%;
	height: 100%;

	&__top {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: $appTopHeight;
		z-index: 10;
	}

	&__page {
		position: absolute;
		top: $appTopHeight;
		left: 0;
		right: 0;
		bottom: $appBottomHeight;

		.app--darkTheme & {
			background: theme-color('dark', 'secondaryColor');
		}
	}

	&__bottom {
		background: #ffffff;
		box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: $appBottomHeight;
		z-index: 10;
	}
}
