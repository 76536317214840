
@function animations($name) {
  @return map-get($bfAnimations, $name);
}


$bfAnimations: (
  'fade': 'fade',
  'fadeRight': 'fadeRight',
  'slideTop': 'slideTop',
  'slideBottom': 'slideBottom',
  'slideUpBack': 'slideUpBack',
  'zoomBack': 'zoomBack'
);
$ANIMATION_BASE_NAME: 'bfAnimation__';
$ANIMATION_TYPE_SEPARATOR: '--';

/**
 * Add an animation to a CSS-block
 * @param name
 **/
@mixin bfAnimation($name: 'fade', $type: 'in', $duration: 0.2s, $easing: ease, $delay: 0s) {
  /* animation: name duration timing-function delay iteration-count direction fill-mode play-state; */
  animation: unquote($ANIMATION_BASE_NAME + '__' + $name + $ANIMATION_TYPE_SEPARATOR + $type) $duration $easing $delay 1 normal forwards;
}



@import './animations/easings';


@import './animations/fade';
@import './animations/fadeRight';
@import './animations/zoomBack';
@import './animations/slideUpBack';
@import './animations/slideBottom';
@import './animations/slideTop';
