@import "../../style/variables";

@-webkit-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-ms-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.bfDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 100;
  overflow: auto;
  color:colors('offBlack');
  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  &__content {
    margin: 100px auto;
    max-width: 500px;
    min-height: 200px;
    background: white;
    box-shadow: 0px 4px 10px 0px rgba(96, 96, 96, 0.5);
    border-radius: 4px;
    animation: dialog 0.5s ease;
    position: relative;
    .bfDialog__contentInner {
      padding: 55px;
      word-wrap: break-word;

      h4 {
        font-weight: weight('bold');
      }
    }
    .app--blueTheme & {
      color:colors('white');
      background: colors('blue');
    }
  }
}
