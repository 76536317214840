@import "../../../../../styles/mixins";
.bfMenu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em 0;
  min-width: 8em;
  max-width: 25em;
  overflow: visible;
  z-index: 10000000000000000;
  opacity: 0;
  transition: opacity 0.1s ease;
  background:colors('white');
  box-shadow:
    0 8px 18px 0 rgba(82, 97, 115, 0.12),
    0 2px 8px 0 rgba(54, 54, 54, 0.25);
  border-radius: 2px;
  .app--darkTheme & {
    background:colors('black');
  }
  &__list {
    display: block;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 25em;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: colors('lightGrey');
    }
    &::-webkit-scrollbar-track {
      background: colors('white');
      border-radius: 0;
    }
  }
  &__item {
    line-height: 2.8em;
    display: block;
    cursor: pointer;
    padding: 0 1.2em;
    position: relative;
    user-select: none;
    color: colors('black');
    .app--darkTheme & {
      color: colors('white');
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    &--seperator {
      background:colors('offWhite');
      height: 1px;
      margin: 3px 0;
      .app--darkTheme & {
        background:colors('offBlack');
      }
      &:hover {
        background:colors('offWhite');
        .app--darkTheme & {
          background:colors('offBlack');
        }
      }
    }
    &:hover {
      background:colors('offWhite');
      .app--darkTheme & {
        background:colors('offBlack');
      }
    }
   // @include ellipsis();

    ui-flag {
      margin-right: 5px;
      .ui-flag.tiny {
        vertical-align: -3px;
      }
    }
  }
  &__description {
    font-size: 0.9rem;
    color: colors('darkGrey');
  }
  &__checkbox {
    position: absolute;
    width: 2rem;
    height: 2rem;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    bfCheckbox {
      display: block;
      pointer-events: none;
    }
    .bfCheckbox {
      position: absolute;
      pointer-events: none;
    }
  }
  &__icon {
    font-size: 15px;
    margin-right: 4px;
  }
  &--showArrow {
    &.bfMenu--top {
      @include arrow(bottom, 6px colors('white'), 0 white);
    }
    &.bfMenu--bottom {
      @include arrow(50%, 6px colors('white'), 0 white);
    }
    &.bfMenu--right {
      @include arrow(1rem, 6px colors('white'), 0 white);
    }
    &.bfMenu--left {
      @include arrow(right, 6px colors('white'), 0 white);
    }
  }
  &--bfMultiSelect {
    color:colors('black');
    .bfMenu {
      &__item {
        &--selected {
          font-weight: weight('semiBold');
        }
        .bf-icon {
          font-size: 12px;
        }

        line-height: 3.5rem;
      }
      &__label {
        padding-left: 2.5rem;
      }
    }
  }
}
